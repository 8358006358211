<template>
<div>
  <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
  <AccessModal
    :modal.sync="modal"
    :accessId="accessId"
    :accessItems="accessItems"
    :edit="edit"
    :title="titleModal"
    @submited="handleSubmit"
    @close="closeModal"
  />
  <CRow>
    <CCol sm="12" class="d-flex justify-content-end">
      <CButton
        color="add"
        class="d-flex align-items-center"
        v-c-tooltip="{
          content: $t('label.access_'),
          placement: 'top-end'
        }"
        @click="toggleAdd"
      >
        <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
      </CButton>
    </CCol>
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="formatedItems"
        :fields="fields"
        column-filter
        :noItemsView="tableText.noItemsViewText"
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :items-per-page="tableText.itemsPerPage"
        hover
        small
        sorter
        pagination
      >
        <template #Status="{ item }">
          <td class="text-center align-middle">
            <CBadge :color="getBadge(item.Status)">
              {{$t('label.'+item.Status)}}
            </CBadge>
          </td>
        </template>
        <template #Detalle="{ item }">
          <td class="text-center align-middle">
            <CButton
              color="edit"
              size="sm"
              class="mr-1"
              v-c-tooltip="{
                content: $t('label.edit')+' '+$t('label.access_'),
                placement: 'top-end'
              }"
              @click="toggleEdit(item)"
            >
              <CIcon name="pencil" />
            </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </CRow>
</div>
</template>

<script>
import { DateFormater } from "@/_helpers/funciones";
import { formatMilDecimal } from '@/_validations/validacionEspeciales';
import General from "@/_mixins/general";
import { mapState } from "vuex";

import AccessModal from "./access-modal";

//DATA
function data() {
  return {
    items: [],
    Loading:  false,
    modal: false,
    accessId: '',
    edit: false,
    accessItems: {},
    titleModal: ''
  };
}

//METHOD
function getListYardAccess() {
this.Loading = true;
this.$http
  .get('YardAccess-list',{ YardId: this.getYardId})
  .then(response => {
    let listado = response.data.data;
    if (listado && listado.length != 0){
        this.items = listado;
    }
  })        
  .finally(() => {
    this.Loading = false;
  });
}

function toggleAdd() {
  this.modal = true;
  this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.access_');
}
function toggleEdit(item) {
  let _lang = this.$i18n.locale;
  this.accessId = item.AccessId;
  this.accessItems = item;
  this.edit = true;
  this.modal = true;
  let title = _lang=='en' ? this.accessItems.AccessNameEn : this.accessItems.AccessNameEs;
  this.titleModal= this.$t('label.edit')+' '+this.$t('label.access_')+': '+title
}
function closeModal(){
  this.modal = false;
  this.edit = false;
  this.accessItems = {};
  this.titleModal = '';
}
function resetDataTab(){
  this.items = [];
}
function handleSubmit() {
  this.getListYardAccess();
}

// COMPUTED
function fields() {
  return [
    {
      key: "Nro",
      label: "#",
      _style: "width:1%;",
      filter: false,
    },
    { key: "AccessNameEs", label:this.$t("label.access_")+' '+('(ES)'),_style: 'width:18%;',_classes:'text-center' },
    { key: "AccessNameEn", label:this.$t("label.access_")+' '+('(EN)'),_style: 'width:18%;',_classes:'text-center' },
    { key: "AccessCode", label:this.$t("label.accessCode"),_style: 'width:18%;',_classes:'text-center'},
    { key: "TpAccessName", label:this.$t("label.type"),_style: 'width:10%;',_classes:'text-center' },
    { key: "Usuario", label: this.$t("label.user"),_style: 'width:10%;',_classes:'text-center' },
    { key: "FormatedDate", label: this.$t("label.date"),_style: 'width:10%;', _classes: "text-center" },
    { key: "Status", label: this.$t("label.status"),_style: 'width:10%;', _classes: "text-center" },
    {
      key: "Detalle",
      label: "",
      sorter: false,
      filter: false,
      _styles: "min-width:45px;",
    },
  ];
}
function formatedItems() {
  let _lang = this.$i18n.locale;
  return this.items.map((items) =>
    Object.assign({}, items, {
      Usuario: items.TransaLogin ? items.TransaLogin : "N/A",
      TpAccessName:  _lang=='en' ? items.TpAccessNameEn : items.TpAccessNameEs,
      Status: items.Status,
      FormatedDate: items.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(items.TransaRegDate) : 'N/A',
      _classes: items.Status === "INACTIVO" ? "table-danger" : "",
    })
  );
}
function getYardId() {
    return this.$store.state.yard.yardSelected.YardId ? this.$store.state.yard.yardSelected.YardId: "";
}
export default {
  name: "subclassification",
  mixins: [General],
  props: {},
  data,
  components: {
    AccessModal,
  },
  mounted() {
    //this.getListYardAccess();
  },
  methods: {
    getListYardAccess,
    toggleAdd,
    toggleEdit,
    closeModal,
    resetDataTab,
    handleSubmit,
  },
  computed: {
    fields,
    formatedItems,
    getYardId,
    ...mapState({
      activeTab: state => state.yard.tabIndex,
      yardSelected: state => state.yard.yardSelected
    }),
  },
  watch: {
    activeTab: function (newTab) {
      if (newTab == 3) {
        this.getListYardAccess();
        this.resetDataTab();
      }
    },
  },
};
</script>
<style scoped>
</style>