<template>
  <form @submit.prevent="submit">
    <loading-overlay
      :active="Loading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      @update:show="resetForm"
    >
      <CRow>
        <CCol sm="12" lg="12">
          <CInput
            v-uppercase
            size="sm"
            :label="$t('label.name')+' '+('(ES)')"
            :placeholder="$t('label.name')+' '+('(ES)')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
            v-model="$v.access.AccessNameEs.$model"
            :is-valid="hasError($v.access.AccessNameEs)"
            :invalid-feedback="errorMessage($v.access.AccessNameEs)"
            addLabelClasses="required text-right"
          />
        </CCol>
        <CCol sm="12" lg="12">
          <CInput
            v-uppercase
            size="sm"
            :label="$t('label.name')+' '+('(EN)')"
            :placeholder="$t('label.name')+' '+('(EN)')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
            v-model="$v.access.AccessNameEn.$model"
            :is-valid="hasError($v.access.AccessNameEn)"
            :invalid-feedback="errorMessage($v.access.AccessNameEn)"
            addLabelClasses="required text-right"
          />
        </CCol>
        <CCol sm="12" lg="12">
          <CInput
            v-uppercase
            size="sm"
            :label="$t('label.accessCode')"
            :placeholder="$t('label.accessCode')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
            v-model="$v.access.AccessCode.$model"
            :is-valid="hasError($v.access.AccessCode)"
            :invalid-feedback="errorMessage($v.access.AccessCode)"
            addLabelClasses="required text-right"
          />
        </CCol>
        <CCol sm="12" lg="12">
          <CSelect
            size="sm"
            :placeholder="$t('label.select')"
            addLabelClasses="required text-right"
            :label="$t('label.accessType')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
            :options="TpAccessOptions"
            v-model="$v.access.TpAccessId.$model"
            :is-valid="hasError($v.access.TpAccessId)"
            :invalid-feedback="errorMessage($v.access.TpAccessId)"
          >
          </CSelect>
        </CCol>
        <CCol sm="12" lg="12">
          <CSelect
            size="sm"
            :placeholder="$t('label.select')"
            addLabelClasses="required text-right"
            :label="$t('label.condition')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
            :options="conditionOptions"
            v-model="$v.access.YardAccesStatusId.$model"
            :is-valid="hasError($v.access.YardAccesStatusId)"
            :invalid-feedback="errorMessage($v.access.YardAccesStatusId)"
          >
          </CSelect>
        </CCol>
        
        <CCol sm="12" lg="12" v-if="edit">
          <CSelect
            size="sm"
            :options="statusOptions"
            :value.sync="access.Status"
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
            addLabelClasses="required text-right"
            :is-valid="statusSelectColor"
          />
        </CCol>
      </CRow>

      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="edit ? statusConfirmation(accessItems.FgActYardAccess, access.Status, submit) : submit()"
        >
        <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="resetForm"
        >
          <CIcon name="x" /><span class="ml-1">{{$t('button.cancel')}}</span>
        </CButton>
    </template>
    </CModalExtended>
  </form>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import UpperCase from '@/_validations/uppercase-directive';
import { FormAccess } from '@/_validations/master-yards/MasterOfYards';
import { mapState } from "vuex";

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    Loading: false,
    access: {
      TpAccessId: '',
      YardAccessId: '',
      AccessNameEs: '',
      AccessNameEn: '',
      AccessCode: '',
      AccessType: '',
      YardAccesStatusId: '',
      Status: 0,
    },
    TpAccess: [],
    conditions: [],
  };
}

function submit() {
  try {
    this.isSubmit = true;
    this.Loading = true;
    this.$v.$touch();
    if (this.$v.$error) {
      this.isSubmit = false;
      this.Loading = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let YardAccesJson = this.edit ? {
        TpAccessId: this.access.TpAccessId,
        YardId: this.getYardId,
        YardAccessId: this.access.YardAccessId,
        AccessNameEs: this.access.AccessNameEs,
        AccessNameEn: this.access.AccessNameEn,
        AccessCode: this.access.AccessCode,
        YardAccesStatusId: this.access.YardAccesStatusId,
        Status: this.access.Status
      }:{
        TpAccessId: this.access.TpAccessId,
        YardId: this.getYardId,
        AccessNameEs: this.access.AccessNameEs,
        AccessNameEn: this.access.AccessNameEn,
        AccessCode: this.access.AccessCode,
        YardAccesStatusId: this.access.YardAccesStatusId,
      };
    let metodo = this.edit ? 'PUT':'POST';
    let ruta = this.edit ? 'YardAccess-update': 'YardAccess-insert';
    this.$http
      .ejecutar( metodo, ruta, YardAccesJson, { root: 'YardAccesJson' })
      .then((response) => {
        if (response && response.status === (200 || 201)) {
          this.$emit('submited');
          this.getYards();
          this.modalActive = false;
          this.notifySuccess({text: response.data.data[0].Response});
          this.resetForm();
        } 
      }).catch((e) => {
        this.notifyError({text: e});
      }).then(() => {
        this.isSubmit = false;
        this.Loading = false;
      });
  } catch (e) {
    this.isSubmit = false;
    this.Loading = false;
    this.notifyError({text: e});
  }
}

function getYards() {
  this.Loading = true;
  this.$http
    .get('Yard-list',{ CompanyBranchId: this.$store.state.auth.branch.CompanyBranchId})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.length != 0){
        let yardItem = listado.find((item)=> item.YardId === this.$store.state.yard.yardSelected.YardId);

        if(yardItem)
          this.$store.state.yard.yardSelected = yardItem;
      }
    })        
    .finally(() => {
      this.Loading = false;
    });
}

function toggle(newVal) {
  if (!newVal)
    this.resetForm();
  else{
    if (this.edit) {
        this.getData(this.accessItems);
    }
  }
  this.modalActive = newVal;
}

function statusSelectColor() {
  return this.access.Status === 1;
}

function getData(val) {
  this.access.TpAccessId = val.TpAccessId;
  //this.getYardId = val.YardId;
  this.access.YardAccessId = val.YardAccessId;
  this.access.AccessNameEs = val.AccessNameEs;
  this.access.AccessNameEn = val.AccessNameEn;
  this.access.AccessCode = val.AccessCode;
  this.access.YardAccesStatusId = val.YardAccesStatusId;
  this.access.Status = val.FgActYardAccess?1:0;
  
  this.$v.$touch();
}

function resetForm() {
  this.access.TpAccessId = '';
  this.access.YardAccessId =  '';
  this.access.AccessNameEs = '';
  this.access.AccessNameEn = '';
  this.access.AccessCode = '';
  this.access.YardAccesStatusId = '';
  this.access.Status = 0;
  this.TpAccess = [],
  this.conditions = [],
  this.Loading = false;
  this.$emit('close');
  this.$v.$reset();
}

async function getListApis() {
  this.Loading = true;
  let peticiones =  
    [
      this.$http.ejecutar("GET", "TpAccess-list", {Filter: 'ACTIVO'}),
      this.$http.ejecutar("GET", "AccessStatus-list", { Filter: 'ACTIVO'}),
    ];
  Promise.all(peticiones)
    .then((responses) => {
      this.TpAccess = responses[0].data.data;
      this.conditions = responses[1].data.data;
      this.Loading = false;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
}

//computed
function TpAccessOptions(){
  let _lang = this.$i18n.locale;
  if(this.TpAccess.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.TpAccess.map(function(e){
      chart.push({
        value: e.TpAccessId, 
        label: _lang=='en' ? e.TpAccessNameEn : e.TpAccessNameEs,
      })    
    })
    return chart;
  }
}
function conditionOptions(){
  let _lang = this.$i18n.locale;
  if(this.conditions.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.conditions.map(function(e){
      chart.push({
        value: e.YardAccesStatusId, 
        label: _lang=='en' ? e.YardAccesStatusDsEn : e.YardAccesStatusDsEs,
      })    
    })
    return chart;
  }
}
function getYardId() {
  return this.$store.state.yard.yardSelected.YardId ? this.$store.state.yard.yardSelected.YardId: "";
}
export default {
  name: 'access-modal',
  props: {
    modal: Boolean,
    edit: {
      type: Boolean,
      default: false,
    },
    accessId: {
      type: String,
      default: "",
    },
    accessItems: {
      type: Object,
      default: "",
    },
    title:{
      type: String,
      default: "",
    }
  },
  data,
  validations(){ return FormAccess() },
  mixins: [
    ModalMixin,
  ],
  directives: UpperCase,
  methods: {
    statusSelectColor,
    getData,
    submit,
    toggle,
    resetForm,
    getListApis,
    getYards
  },
  computed: {
    TpAccessOptions,
    conditionOptions,
    getYardId,
    ...mapState({
      yardSelected: state => state.yard.yardSelected
    }),
  },
  watch: {
    modal: function(val){
      this.toggle(val);
      if (val) {
        this.getListApis();
      }
      
    },
    modalActive: function (val) {
      this.$emit('update:modal', val);
    }
  },
 
};
</script>